/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48I2YyYjAzYyIsIj9lcjwjZmJlN2M1IiwiO2VyPCNlYzk3Mjd$LCIlPmBePCM0NDhhZmYiLCI~ZXI8I2M3ZGNmZiIsIjtlcjwjMmQ2ZGZmfiwid2Fybj5gXjwjY2IyMDI2IiwiP2VyPCNlZmJjYmUiLCI7ZXI8I2I4MTMxN34sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjEzfQ==
*/

@use '@angular/material' as mat;

@use 'sass:math';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Symbols+Outlined';

$fontConfig: (
  display-4: mat.define-typography-level(112px, 112px, 300, 'Inter', -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, 'Inter', -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, 'Inter', 0em),
  display-1: mat.define-typography-level(34px, 40px, 400, 'Inter', 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, 'Inter', 0em),
  title: mat.define-typography-level(20px, 32px, 500, 'Inter', 0.0075em),
  subheading-2: mat.define-typography-level(16px, 28px, 400, 'Inter', 0.0094em),
  subheading-1: mat.define-typography-level(15px, 24px, 500, 'Inter', 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 500, 'Inter', 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, 'Inter', 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, 'Inter', 0em),
  caption: mat.define-typography-level(12px, 20px, 400, 'Inter', 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, 'Inter', 1.5px),
);

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

/*// Compute font config
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($fontConfig);`
@include mat.all-component-typographies($fontConfig);
@include mat.core();*/

// Theme Config

body {
  --primary-color: #448aff;
  --primary-lighter-color: #c7dcff;
  --primary-darker-color: #2d6dff;
  --text-primary-color: #{$dark-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary: (
  main: #448aff,
  lighter: #c7dcff,
  darker: #2d6dff,
  200: #448aff,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #2d70a8;
  --accent-lighter-color: #c0d4e5;
  --accent-darker-color: #1c538d;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
  main: #2d70a8,
  lighter: #c0d4e5,
  darker: #1c538d,
  200: #2d70a8,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #cb2026;
  --warn-lighter-color: #efbcbe;
  --warn-darker-color: #b81317;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: #cb2026,
  lighter: #efbcbe,
  darker: #b81317,
  200: #cb2026,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
$theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: $fontConfig,
    is-dark: false,
    foreground: $mat-light-theme-foreground,
    background: $mat-light-theme-background,
  )
);

$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    foreground: $mat-dark-theme-foreground,
    background: $mat-dark-theme-background,
  )
);

@include mat.core();
@include mat.all-component-themes($theme);

@media (prefers-color-scheme: dark) {
  @include mat.all-component-colors($dark-theme);
}

.mat-expansion-panel-header.mat-expanded {
  height: 64px;
  font-family: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
  font-family: inherit;
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-stepper-horizontal,
.mat-stepper-vertical {
  background-color: transparent;

  .mat-vertical-content,
  .mat-horizontal-content-container {
    padding: 0 0 0 0;
  }
}

.mat-mdc-unelevated-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-mdc-unelevated-button.mat-primary,
.mat-flat-button.mat-accent {
  color: white !important;
}

.mat-mdc-raised-button.mat-primary,
.mat-raised-button.mat-accent {
  color: white !important;
}

@media (prefers-color-scheme: light) {
  .mat-step-icon-state-error .mat-step-icon-content {
    color: inherit !important;
  }
  .mat-step-icon-content {
    color: white;
  }
}

.mat-mdc-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';

  .mat-badge-content {
    font-family: 'Roboto';
  }
}

/*!*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*!
.mat-form-field-label {
  line-height: 16px !important;
}

!*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*!
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.4em) perspective(100px) scale(0.85) translateZ(0.001px);
  -ms-transform: translateY(-1.4em);
  width: 133.3333333333%;
}

!*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*!
.mat-form-field-prefix {
  align-self: flex-end;
  margin-bottom: 2px;
}*/

.mat-mdc-form-field {
  font-family: inherit !important;
  letter-spacing: unset !important;
}

.mat-expansion-panel-content {
  font-family: inherit !important;
}

.mat-step-header .mat-step-icon-state-error {
  color: var(--warn-color) !important;
}

button.country-list-button {
  color: inherit !important;
}

.snackbar-error {
  --mdc-snackbar-container-color: #cb1010; /* Same color as bg-red-500 */
  --mat-mdc-snack-bar-button-color: #ffffff;
  --mdc-snackbar-supporting-text-color: #ffffff;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: inherit !important;
  line-height: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  letter-spacing: inherit !important;
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: block;
  border-radius: 4px;
  background-color: var(--mat-datepicker-calendar-container-background-color);
  color: var(--mat-datepicker-calendar-container-text-color);
}
