@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';


body {
  margin: 0;
}

@tailwind base;

@tailwind components;

@tailwind utilities;

@font-face {
  font-family: Inter;
  src: url('assets/Inter.otf') format('opentype');
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Italic.otf') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Thin.otf') format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-ThinItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-SemiBold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-SemiBoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-ExtraBold.otf') format('opentype');
  font-weight: 800;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-ExtraBoldItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Black.otf') format('opentype');
  font-weight: 900;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

html {
  font-family: Inter;
  font-feature-settings: 'tnum' off, 'lnum' on, 'zero' off, 'ss01' on;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
}

@media (prefers-color-scheme: light) {
  html {
    color: #12141d;
  }
}

@media (prefers-color-scheme: dark) {
  html {
    color: white;
  }
}

.mapboxgl-map {
  font: inherit !important;
}

@layer components {
  .card-with-shadow {
    @apply rounded shadow print:shadow-none;
  }

  .card-with-shadow-white-no-radius {
    @apply shadow bg-white dark:bg-zinc-900 print:bg-white print:shadow-none;
  }

  .card {
    @apply shadow bg-white dark:bg-zinc-900 print:bg-white print:shadow-none;
  }

  .card-no-radius {
    @apply bg-white dark:bg-zinc-900;
  }
}

.has-tooltip {
  @apply underline decoration-dotted cursor-help;
}

.alternating-row-even {
  @apply even:bg-gray-100 dark:even:bg-gray-900;
}

.alternating-row-odd {
  @apply odd:bg-gray-100 dark:odd:bg-gray-900;
}

.tooltip-overrides {
  max-width: 300px !important;
  border-radius: 6px !important;
  text-align: left;
  @apply p-2 font-normal text-black dark:text-white text-sm bg-white shadow dark:bg-gray-900 border border-gray-600 dark:border-slate-800 !important;
}

.version-warning {
  transition: bottom 1s;
  z-index: 100000;
  bottom: -40px;
  width: 100%;
  position: fixed;
  display: flex;
  min-width: 100vw;
  max-width: 100vw;
  margin: 0;
  border-radius: 0px;
  background-color: #feb300;
  align-items: center;
  opacity: 0;
}

.version-warning-text {
  justify-content: center;
  line-height: 24px;
  text-align: center;
  color: black;
  padding: 8px;
  width: 100%;
  font-size: 20px;
}

.wrong-version {
  bottom: 0;
  opacity: 1;
}

.str-chat {
  height: inherit !important;
}

.str-chat__main-panel {
  padding: 0 !important;
}

.mat-icon.mat-icon-inline {
  @apply align-middle;
}

.mat-mdc-tab-link {
  opacity: inherit !important;
}

.warning-response-needed {
  display: inline-block;
  position: relative;
  animation: bounce 1s infinite linear;
}

@keyframes bounce {
  0% {
    top: 0;
  }
  25% {
    top: -0.2em;
  }
  35% {
    top: -0.3em;
  }
  50% {
    top: 0;
  }
}

.mat-mdc-dialog-actions {
  @apply gap-x-2 justify-between !important;
}

.color-transition {
  @apply transition-colors duration-150;
}

.terms h3 {
  @apply text-xl font-semibold;
}

.terms p {
  @apply mb-1
}

.terms ul {
  @apply list-disc pl-2 py-1;
}

.terms a {
  @apply underline text-blue-500;
}

.no-padding-dialog .mdc-dialog__content {
  @apply p-0 overflow-hidden lg:max-h-[400px] bg-white dark:bg-zinc-900/75 backdrop-blur border border-gray-600 dark:border-slate-800 !important;
}

td-carrier-assistant-chat ul {
  @apply list-inside list-disc pl-1 first:pt-0 pt-1 !important;
}

td-carrier-assistant-chat ol {
  @apply list-inside list-decimal pl-1 first:pt-0 pt-1 !important;
}

td-carrier-assistant-chat p {
  @apply first:pt-0 pt-1 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.sliced-gradient {
  background-image: linear-gradient(153deg, var(--tw-gradient-from) -8% 60%, 55%, var(--tw-gradient-to) 100%)
}

.dont-break-me-down div {
  @apply print:break-inside-avoid;
}

@media print {
  .print-bg-image {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important; /* Firefox 48 – 96 */
    print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
  }

  .not-final {
    background-image: url('assets/images/notfinal.png');
  }
}


.unset .mat-expansion-panel-header.mat-expanded {
  @apply h-[unset] py-2 !important;
  font-family: inherit;
}

.unset .mat-expansion-panel-header {
  @apply h-[unset] py-2 !important;
  font-family: inherit;
}

.cdk-drag-preview {
  @apply shadow transition-shadow duration-150 bg-inherit !important;
}

.cdk-drop-list-dragging,
.cdk-drop-list-receiving {
  @apply cursor-grabbing min-h-10 transition-colors duration-100 bg-gray-100 dark:bg-gray-900 !important;
}

.cdk-drop-list-dragging .hide, .cdk-drop-list-receiving .hide {
  @apply hidden !important;
}

.cdk-drag-placeholder {
  @apply shadow-inner transition-shadow duration-150 cursor-grabbing !important;
}

.card-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  @apply bg-white dark:bg-zinc-900 border border-blue-500 !important;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
